export const API = 'https://chania.mitos.cityguideplatform.com/api'
export const APIEvents = 'https://events.explorechania.gr/api'

export const Header = {
  'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq',
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
  'Content-Type': 'multipart/form-data'
}

export const Cors = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
}

export const HeaderForUser = { 'X-Authorization': 'hfG7u8C4UNMw17E6Jyzq'}
