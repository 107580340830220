import React, {useState, useEffect} from "react"
import { Button, Modal, Form, Input, notification, Divider, Col, Checkbox, message } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useUserRegister } from '../../../hooks/useUser'
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

interface Props{
    clickModal: (one : number) => void,
}

const ModalRegisterForm: React.FC<Props> = ({clickModal}) => {

    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [api, contextHolder] = notification.useNotification()
    const [messageApi, contextHolderMes] = message.useMessage();
    const [username, setUsername] = useState<string>('') 
    const [email, setEmail] = useState<string>('') 
    const [password, setPassword] = useState<string>('')
    const [password2, setPassword2] = useState<string>('')
    const [checkBox, setCheckBox] = useState<boolean>(false)

    const error = (text:string) => {
        messageApi.open({
            type: 'error',
            content: text,
        });
    };

    const openNotification = (title: string, text:string) => {
        api.info({
        message: title,
        description: text,
        placement: 'top',
        });
    }

    const { Message, refetch } = useUserRegister(username, email, password)

    const closeModal = () => {
        Modal.destroyAll()
        clickModal(1)
    }

    const showModal = () => {
        Modal.info({
            centered: true,
            closable: true,
            footer: null,
            title: t('Δημιουργία Λογαριασμού'),
            width: 460,
            content: (
                <>
                    <br />
                    <Form
                        layout="vertical"
                        autoComplete="off"
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinish={onFinish}
                        style={{ maxWidth: 360 }}
                    >
                        <Form.Item label={t('Όνομα')} name="username" hasFeedback rules={[{ required: true, message: `${t('Πληκτρολόγησε το όνομά σου')}`  }]}>
                            <Input prefix={<UserOutlined className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε το όνομά σου εδώ') || undefined }/>
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}
                        
                        <Form.Item label="Email" name="email" hasFeedback rules={[{ type:'email', required: true, message: `${t('Enter your Email')}` }]}>
                            <Input prefix={<MailOutlined  className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε το Email σου εδώ') || undefined } />
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}

                        <Form.Item label={t('Κωδικός')} name="password" hasFeedback rules={[
                            { required: true, message: `${t('Please input your password!')}` },
                            { min: 8, message: `${t('Password must be minimum 8 characters.')}` },
                        
                        ]}>
                            <Input.Password prefix={<LockOutlined className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε τον κωδικό σου εδώ') || undefined } />
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}

                        <Form.Item label={t('Επιβεβαίωση Κωδικού')} name="password2" hasFeedback rules={[{ required: true, message: `${t('Please input your password!')}` }]}>
                            <Input.Password prefix={<LockOutlined className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε τον κωδικό σου εδώ') || undefined }/>
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}


                        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 22 }}>      
                            <div className={classes.checkBox}>
                                <Checkbox></Checkbox>&nbsp;&nbsp;
                                {t('Συμφωνώ με τους')} <a href= '../page/terms' target="_blank" className={classes.underline}>{t('Όρους Χρήσης')}</a>{t('και την')}<a href='../page/policy' target="_blank" className={classes.underline} >{t('Πολιτική Απορρήτου')}</a>
                            </div>
                        </Form.Item>

                        <Form.Item>
                            <Button htmlType="submit" className={classes.signUpBtn}>{t('Εγγραφή')}</Button>
                            <Button htmlType="button" className={classes.signUpBtn} onClick={onReset}>{ t('Επαναφορά')}</Button>
                            {/* <Button type="link" htmlType="button" onClick={onFill} >Συμπληρώστε τη φόρμα Sergios...</Button> */}
                        </Form.Item>

                        <div className={classes.textBold} >
                            {t('Έχεις ήδη εγγραφεί;')} <span className={classes.textBoldSignIn} onClick={()=> closeModal()}>{t('Συνδέσου στο λογαριασμό σου')}</span> 
                        </div>
                    </Form>
                </>
            ),
            onOk() { },
        });
    };

    useEffect(() => {
        if (Message)
            openNotification(t('Δημιουργία Λογαριασμού'), t('Σας έχουν σταλεί οδηγίες ολοκλήρωσης εγγραφής στο Ηλ. ταχυδρομείο σας. Παρακαλώ ελέγξτε τα Εισερχόμενα  ή τον φάκελο Spam'))
    }, [Message])

    useEffect(() => {
        if (email !== '' && password !== '')
            refetch()
    }, [username, email, password])

    
    const onFinish = (values: any) => {

        if (values.remember == true) {
            if (values.password === values.password2) {
                setUsername(values.username)
                setEmail(values.email)
                setPassword(values.password)
                setPassword2(values.password2)
                setCheckBox(values.remember)
                form.resetFields()
                Modal.destroyAll()
                openNotification(t('Δημιουργία Λογαριασμού'), t('Σας έχουν σταλεί οδηγίες ολοκλήρωσης εγγραφής στο Ηλ. ταχυδρομείο σας. Παρακαλώ ελέγξτε τα Εισερχόμενα ή τον φάκελο Spam'))
            }
            else 
                error(t('Οι κωδικοί δεν ταιριάζουν'))
        }
        else
            error(t('Παρακαλώ αποδεχτείτε τους Όρους χρήσης και την Πολιτική Απορρήτου')) 
    }


    const onReset = () => {
        form.resetFields();
    }

    const onFill = () => {
        form.setFieldsValue({ username:'sergios', email: 'ser11@mail.ru', password: '12345678', password2: '12345678' });
    }


    return (
        <>
            {contextHolder}
            {contextHolderMes}
            <Button size='large' className={classes.FavouritesBtn} onClick={showModal}> {t('Δημιουργία Λογαριασμού')}</Button>
        </>
    )
}
export default ModalRegisterForm
