import React from 'react'
import { Row, Col, Carousel} from 'antd'
import classes from "./Slider.module.css"
import { SampleNextArrow, SamplePrevArrow } from '../../../components/ArrowsForCarousel/Slider'

interface Props{
  images: string[]
}

const SliderComponent: React.FC<Props> = ({images}:Props) => {

  const settings = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }  

  return ( 
    <div className={classes.container}>
      <Row justify="center">
        <Col span={24}>
          <div className={classes.sliderContainer}>
            <Carousel autoplay {...settings}>
              {images && images.map((image:any, index:any) => {
                return (
                  <div key={index}>
                    <div className={classes.helper}>
                      <img alt={image.alternative_text} src={image.url} />
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SliderComponent
