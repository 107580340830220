
import React, {useState, useEffect, lazy} from "react"
import { Row, Col, Button, Select, Drawer, Space  } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import i18n from '../../translation'
import { useExploration, usePoises} from '../../hooks/useExploration'
import { useAreas } from '../../hooks/useAreas'
import { useLocation } from "react-router-dom"
import utils from '../../utils'
import HelmetComponent from '../../components/Helmet'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitle from '../../components/PageComponents/TitleAndSubtitle'
import { CloseOutlined } from '@ant-design/icons'
import CheckBoxComponent from './checkBoxGroup'
import ViewPointsComponent from './viewPoints'
// import LiefletComponent from './Lieflet'


import EnvoromentsCheckBoxesComponent from './NewCheckBoxes/Enviroments'
import HistoryCheckBoxesComponent from './NewCheckBoxes/History'
// import EnvironmentsCheckBoxComponent from '../../pages/Search/CheckBoxes/environments'


import MapSvg from '../../img/svg/map'
import EnvironmentSvg from '../../img/svg/environment'
import HistorySvg from '../../img/svg/history'
import GlobusSvg from '../../img/svg/globus'

import { PoisCategoriesProps, subCategoriesProps } from "../../hooks/props/exploration"

// import {PoisesData} from './poises'
import { PoisesDataEl } from './poisesGr'
import { PoisesDataEn } from './poisesEn'
import { PoisesDataRu } from './poisesRu'
import { PoisesDataFr } from './poisesFr'
import { PoisesDataDe } from './poisesDe'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { EventsPageNumberRedux }  from '../../redux/mapPage/event'
import type { RootState } from '../../redux/store'


const LiefletComponent = lazy(() => import("./Lieflet"))


const Exploration: React.FC = () => {

  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const { isLoading, poisCategories, refetch } = useExploration()
  const [poisesDataLang, setPoisesDataLang] = useState<any[]>(PoisesDataEl);
  // const { Poises } = usePoises()
  // console.log(Poises)

  const [checkedId] = useState<number | 21>(location?.state?.typeId)
 
  const { areas } = useAreas()

  const ReduxExplorationPageNumber: number = useSelector((state: RootState) => state.Categories.ExplorationPageNumberRedux)
  const ReduxHistory: any[] = useSelector((state: RootState) => state.Categories.History)
  const ReduxEnvironment: any[] = useSelector((state: RootState) => state.Categories.Environment)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Categories.Municipality)

  const [historyName, setHistoryName] = useState<string>('');
  const [historySubCategories, setHistorySubCategories] = useState<any[]>([]);

  const [environmentName, setEnvironmentName] = useState<string>('');
  const [environmentSubCategories, setEnvironmentSubCategories] = useState<any[]>([]);

  const [municipalities, setMunicipalities] = useState<any[]>([]);

  const [selectSort, setSelectSort] = useState<string>('');

  const [poises, setPoises] = useState<any[]>([]);

  const [setPoisesMarkerExploration, PoisesMarkerExploration ] = useState<any[]>([]);


  const [showMap, setShowMap] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [opacityMap, setOpacityMap] = useState<boolean>(true);
  

  useEffect(() => {
    if (poisCategories[0] !== undefined) {

      setHistoryName(poisCategories[0].name)
      setHistorySubCategories( poisCategories[0].subCategories )

      setEnvironmentName(poisCategories[1].name)
      setEnvironmentSubCategories(poisCategories[1].subCategories)

      setMunicipalities(areas)
    }
  }, [poisCategories[0]])


  useEffect(() => {

    let result = utils.filterPoisesArray(poisesDataLang, ReduxEnvironment, ReduxHistory, ReduxMunicipalities, selectSort)
    result.length >= 1 ? setPoises(result) : setPoises([])
    
  },[ poisesDataLang, ReduxEnvironment, ReduxHistory, ReduxMunicipalities, selectSort ])


  useEffect(() => {
    refetch()
    dispatch(EventsPageNumberRedux(1))
    switch (i18n.language) {
      case 'gr': setPoisesDataLang(PoisesDataEl); break;
      case 'en': setPoisesDataLang(PoisesDataEn); break;
      case 'ru': setPoisesDataLang(PoisesDataRu); break;
      case 'fr': setPoisesDataLang(PoisesDataFr); break;
      case 'de': setPoisesDataLang(PoisesDataDe); break;
    }
    
    // setShowMap(true)
    
  }, [i18n.language])


  const handleChange = (value: string) => {
    setSelectSort(value)
  };


  const showDrawer = () => { setOpen(true) }

  const onClose = () => { setOpen(false) }


  const MySelectOptions = () => {
    return (
      <Select
        defaultValue={`${t('Επιλογές Προορισμών')}`}
        placeholder={t('Ταξινόμηση ανά:')}
        size='large'
        style={{ width: 260, marginTop: '-5px' }}
        onChange={handleChange}
        options={[
          // {
          //   value: 'choise',
          //   label: <span className={classes.useful}>{t('Κορυφαίες Επιλογές')}</span>,
          // },
          {
            value: 'route',
            label: <span className={classes.useful}>{t('Επιλογές Προορισμών')}</span>,
          },
          {
            value: 'rate',
            label: <span className={classes.useful}>{t('Υψηλότερο Rating')}</span>,
          }
        ]}
      />
    )
  }


  if (environmentSubCategories.length == 0) {
    setTimeout(() => {
      setShowMap(false)
      setOpacityMap(false)
    }, 1000);
    return (<Spinner />)
  }

  return (
    <>
      <HelmetComponent
          title={t('explorations_title') as string}
          descrition={t('explorations_description') as string}
          keywords={t('explorations_keywords') as string}
      />

      <TitleAndSubtitle title={t('Εξερεύνησε τα μοναδικά αξιοθέατα των Χανίων')} subTitle={t('Βρες τα σημεία που σε ενδιαφέρουν στο Χάρτη')} />
      
      {showMap &&
        
        <div className={classes.MainContainer}  >
            <div className={classes.container}>
              <Row className={ opacityMap ? classes.opacityZero : classes.opacityOne}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className={[classes.btn, classes.btnPadding].join(" ")}>
                    <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; { !showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
                  </div>
                </Col>
              
                <Col xs={24} sm={24} md={24} lg={24}>
                  <LiefletComponent setPoisesMarkerExploration={setPoisesMarkerExploration} />
                </Col>
              </Row>
          </div>
        </div>
        }

        <div className={classes.MainContainer}>
          <Row justify="center">
            
          <Drawer
            bodyStyle={{padding:'0px'}}
            autoFocus={false}
            mask={false}
            title={t('Φίλτρα')}
            placement={'left'}
            closable={false}
            onClose={onClose}
            open={open}
            // getContainer={false}
            width={350}
            extra={
              <Space>
              <Button
                icon={<CloseOutlined />}
                onClick={onClose}
                type="text"
                style={{ position: 'absolute', top: 8, right: 0 }}
              />
            </Space>
            }
          >
            <div className={classes.checkBoxesContainer}>
              <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
                <CheckBoxComponent
                  checkedId={checkedId}
                  header={environmentName}
                  array={environmentSubCategories}
                  icon={<EnvironmentSvg width={30} height={30} fill="#275674" />} type={'environment'} />
              
              <hr />
                <CheckBoxComponent
                  checkedId={checkedId}
                  header={historyName}
                  array={historySubCategories}
                  icon={<HistorySvg width={32} height={32} fill="#275674" />} type={'history'} />
              <hr />
                <CheckBoxComponent
                  checkedId={0}
                  header={t("Δήμος") || ''}
                  array={municipalities}
                  icon={<GlobusSvg width={30} height={30} fill="#275674" />} type={'municipality'} />
            </div>
          </Drawer>

          
            <Col xs={24} sm={24} md={5} lg={5}>
              <span className={classes.hideIsMobile}>
                <div className={classes.checkBoxesContainer}>
                  <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
                    {/* <CheckBoxComponent
                      checkedId={checkedId}
                      header={environmentName}
                      array={environmentSubCategories}
                      icon={<EnvironmentSvg width={30} height={30} fill="#275674" />} 
                      type={'environment'} /> */}

                  <EnvoromentsCheckBoxesComponent
                    checkedId={checkedId}
                    header={environmentName}
                    array={environmentSubCategories}
                    icon={<EnvironmentSvg width={30} height={30} fill="#275674"  />}
                    type={'environment'}
                  />

                  <hr />
                    <HistoryCheckBoxesComponent
                      checkedId={checkedId}
                      header={historyName}
                      array={historySubCategories}
                      icon={<HistorySvg width={32} height={32} fill="#275674" />}
                      type={'history'} />
                  <hr />
                    <CheckBoxComponent
                      checkedId={0} header={t("Δήμος") || ''}
                      array={municipalities}
                      icon={<GlobusSvg width={30} height={30} fill="#275674" />} type={'municipality'} />
                </div>
              </span>
            </Col>
       
          
            <Col xs={24} sm={24} md={19} lg={19}>
              <div className={classes.checkBoxesContainerPoint}>
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12}>

                    <div className={classes.pointTitle}>{t('Σημεία για εξερεύνηση στα Χανιά')}</div>
                  </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className={classes.btnFiltersAndMap}>
                    <div>
                      <Button
                        type="primary"
                        size='large'
                        className={classes.btnFilters}
                        onClick={showDrawer}> {t('Φίλτρα')}
                        {/* onClick={() => setCheckBoxesContainerVisible(!checkBoxesContainerVisible)}> {t('Φίλτρα')} */}
                      </Button>
                    </div>
                    {
                      !showMap &&
                      <div className={classes.btn}>
                        <Button
                          type="primary"
                          icon={<MapSvg width={20} height={20} fill="#fff" />}
                          size='large' className={classes.btnMap}
                          onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')}
                        </Button>
                      </div>
                    } 
                  </div>
                  
                  </Col>
                </Row>
              </div>

              <Row>
                <div className={classes.checkBoxesContainerPoint2} >
                  <Col xs={24} sm={24} md={24} lg={24}>

                  <div className={classes.sortDiv}> {t('Ταξινόμηση ανά')}:</div>

                  <MySelectOptions />

                  </Col></div>
              </Row>

              <Row justify="center">
                <Col xs={24} sm={24} md={24} lg={24}>
                  
                  {poises.length !== 0
                    
                    ?
                    <ViewPointsComponent
                      lg={24}
                      xs={24}
                      paginationNumber = {ReduxExplorationPageNumber}
                      array={poises}
                      total={poises.length}
                      isAddOrDelete={true}
                      setPoisesMarkerExploration={PoisesMarkerExploration}
                      onClickAddRemove={ ()=> ''} />
                    :

                    <div className={classes.dontHave}>
                      { t('Δεν υπάρχουν σημεία με τα συγκεκριμένα κριτήρια')}
                    </div>
                  } 

                </Col>
              </Row>

            </Col>
          </Row>

        </div>
    </>
  )
}

export default Exploration
