import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { EventProps, EventsProps } from "../hooks/props/event"
import { axiosEvents } from "../services/events"


export const useEvent = (id: string) => {
  
  const [EventData, setEvent] = useState<EventProps>();

  const { isLoading, isFetching, refetch, error } = useQuery(['event', id], () => axiosEvents.getEvent(id), {

    onSuccess: (data) => {

      let array: EventProps;
      let timePeriodsArray: any[] = []
      let timePeriodsArrayTwo: any[] = []

      let hours = ''
      let start_date = ''
      let end_date = ''
      let start_dateTwo = ''
      let end_dateTwo = ''
      const optionsForDate = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: '2-digit', year: 'numeric' } as const;
      const optionsForDateTwo = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: 'string', year: 'numeric' } as const;

      if (data.time_periods.length == 0) {
          
        hours = '12:00 - 12:00'
        start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
        end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
        timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
      
      }
      else {
        hours = `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((data.time_periods[0].starting_date * 1000) )}  -  ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((data.time_periods[0].ending_date * 1000))}`
        
        for (let i = 0; i < data.time_periods.length; i++) {

          start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format((data.time_periods[i].starting_date * 1000))
          end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format((data.time_periods[i].ending_date * 1000))
      
          start_dateTwo = new Intl.DateTimeFormat('el-GR', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'Europe/Athens', hour12: false }).format((data.time_periods[i].starting_date * 1000) );
          end_dateTwo = new Intl.DateTimeFormat('el-GR', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'Europe/Athens', hour12: false }).format((data.time_periods[i].ending_date * 1000));
         
          timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
          timePeriodsArrayTwo.push({ starting_date: start_dateTwo, ending_date: end_dateTwo })
        }
      }

      array = {
        id: data.id,
        title:data.title,
        category: data.category.title,
        municipality: data.client.title,
        municipality2: setMunicipality(data.client.title),
        description: data.description,
        image: data.image.url,
        time_periods: timePeriodsArray,
        time_periodsTwo: timePeriodsArrayTwo,
        venues: data.venues,
        urls: data.urls,
        price: data.has_free_entry == true ? 'free' : (data.has_free_entry == false && data.has_external_tickets == true) ? 'out' : data.tickets,
        gallery: data.gallery,
        category_id: data.category.id
        // id_item: listData.id.toString(),
        // place: listData.client.title,
        // municipality: setMunicipality(listData.client.title),
        // image: listData.image.url,
        // title: listData.title,
        // description: listData.description,
        // lat: lat,
        // lng: lng,
        // category_id: listData.category.id,
        // category_type: listData.category.title,
        // month_code: shortMonth,
        // hour: hours,
        // price: listData.tickets,
        // time_periods: timePeriodsArray,
        // venues: listData.venues
      }



      setEvent(array) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, refetch, error, EventData }
}


export const useEventsCurrent = () => {
  
  const [EventsCurrent, setEventsCurrent] = useState<EventsProps[]>([]);

  const { isLoading, isFetching, error, refetch } = useQuery(['eventsCurrent'], () => axiosEvents.getEventsCurrent(), {

    onSuccess: (data) => {

      let now = new Date();
      let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let nowTimestamp = startOfDay.getTime() / 1000

      let array: EventsProps[] = []
      let lat = '0'
      let lng = '0'
      let hours = ''
      let start_date = ''
      let end_date = ''
      let timePeriodsArray: any[] = []
      let shortMonth: any[] = []
      const optionsForDate = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: '2-digit', year: 'numeric' } as const;

      data.map((listData: any) => {

        if (listData.time_periods == '' || !(nowTimestamp >= listData.time_periods[0].ending_date)) {

          let MonthsArray: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

          if (listData.time_periods != '') {

            let startMonth = 0
            let finishMonth = 0
            let removedItems: any[] = []

            let starting_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].starting_date * 1000))
            let ending_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].ending_date * 1000))

            if (starting_date == ending_date) {
              removedItems = [starting_date]
            }

            for (var i = 0; i < MonthsArray.length; i++) {

              if (MonthsArray[i] == starting_date)
                startMonth = MonthsArray.indexOf(MonthsArray[i]);
            
              if (MonthsArray[i] == ending_date)
                finishMonth = MonthsArray.indexOf(MonthsArray[i]);
            }


            if (startMonth < finishMonth) {
              removedItems = MonthsArray.splice(startMonth, finishMonth - startMonth + 1)
            }

            else if (startMonth > finishMonth) {
              removedItems = MonthsArray.splice(startMonth, startMonth - finishMonth)
              removedItems = [...removedItems, ending_date]
            }
  
            shortMonth.push([...removedItems])
          }

          else {
            shortMonth.push(...MonthsArray)
          }

          
          if (listData.venues.length != 0 && listData.venues[0].coordinate_x != null) {
            lat = listData.venues[0].coordinate_x
            lng = listData.venues[0].coordinate_y
          }
          else {
            lat = '0'
            lng = '0'
          }

          if (listData.time_periods.length == 0) {
          
            hours = '12:00 - 12:00'
            start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
            end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
            timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
          
          }
          else {
            hours = `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].starting_date * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].ending_date * 1000))}`
          
            for (let i = 0; i < listData.time_periods.length; i++) {

              start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(listData.time_periods[i].starting_date * 1000)
              end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(listData.time_periods[i].ending_date * 1000)
          
              timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
            }
          }
         
          array.push({
            id: `e_${listData.id.toString()}`,
            id_item: listData.id.toString(),
            place: listData.client.title,
            municipality: setMunicipality(listData.client.title),
            image: listData.image.url,
            title: listData.title,
            description: listData.description,
            lat: lat,
            lng: lng,
            category_id: listData.category.id,
            category_type: listData.category.title,
            month_code: shortMonth,
            hour: hours,
            price: listData.has_free_entry == true ? 'free' : (listData.has_free_entry == false && listData.has_external_tickets == true) ? 'out' : listData.tickets,
            time_periods: timePeriodsArray,
            venues: listData.venues
          })

          timePeriodsArray = []
          shortMonth = []
        }
      });
      
      setEventsCurrent(array) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, EventsCurrent, refetch }
}

export const useEventsUpcoming = () => {
  
  const [EventsUpcoming, setEventsUpcoming] = useState<EventsProps[]>([]);

  const { isLoading, isFetching, error } = useQuery(['eventsUpcoming'], () => axiosEvents.getEventsUpcoming(), {

    onSuccess: (data) => {

      let now = new Date();
      let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let nowTimestamp = startOfDay.getTime() / 1000

      let array: EventsProps[] = []
      let lat = '0'
      let lng = '0'
      let hours = ''
      let start_date = ''
      let end_date = ''
      let timePeriodsArray: any[] = []
      let shortMonth: any[] = []
      const optionsForDate = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: '2-digit', year: 'numeric' } as const;

      data.map((listData: any) => {

        if (listData.time_periods == '' || !(nowTimestamp >= listData.time_periods[0].ending_date)) {

          let MonthsArray: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

          if (listData.time_periods != '') {

            let startMonth = 0
            let finishMonth = 0
            let removedItems: any[] = []

            let starting_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].starting_date * 1000))
            let ending_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].ending_date * 1000))

            if (starting_date == ending_date) {
              removedItems = [starting_date]
            }

            for (var i = 0; i < MonthsArray.length; i++) {

              if (MonthsArray[i] == starting_date)
                startMonth = MonthsArray.indexOf(MonthsArray[i]);
            
              if (MonthsArray[i] == ending_date)
                finishMonth = MonthsArray.indexOf(MonthsArray[i]);
            }


            if (startMonth < finishMonth) {
              removedItems = MonthsArray.splice(startMonth, finishMonth - startMonth + 1)
            }

            else if (startMonth > finishMonth) {
              removedItems = MonthsArray.splice(startMonth, startMonth - finishMonth)
              removedItems = [...removedItems, ending_date]
            }
  
            shortMonth.push([...removedItems])
          }

          else {
            shortMonth.push(...MonthsArray)
          }

          
          if (listData.venues.length != 0 && listData.venues[0].coordinate_x != null) {
            lat = listData.venues[0].coordinate_x
            lng = listData.venues[0].coordinate_y
          }
          else {
            lat = '0'
            lng = '0'
          }

          if (listData.time_periods.length == 0) {
          
            hours = '12:00 - 12:00'
            start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
            end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
            timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
          
          }
          else {
            hours = `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].starting_date * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].ending_date * 1000))}`
          
            for (let i = 0; i < listData.time_periods.length; i++) {

              start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format((listData.time_periods[i].starting_date * 1000))
              end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format((listData.time_periods[i].ending_date * 1000))
          
              timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
            }
          }
         
          array.push({
            id: `e_${listData.id.toString()}`,
            id_item: listData.id.toString(),
            place: listData.client.title,
            municipality: setMunicipality(listData.client.title),
            image: listData.image.url,
            title: listData.title,
            description: listData.description,
            lat: lat,
            lng: lng,
            category_id: listData.category.id,
            category_type: listData.category.title,
            month_code: shortMonth,
            hour: hours,
            price: listData.has_free_entry == true ? 'free' : (listData.has_free_entry == false && listData.has_external_tickets == true) ? 'out' : listData.tickets,
            time_periods: timePeriodsArray,
            venues: listData.venues
          })

          timePeriodsArray = []
          shortMonth = []
        }
      });
      
      setEventsUpcoming(array) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, EventsUpcoming }
}


export const useEventsMap = () => {
  
  const [Events, setEvents] = useState<EventsProps[]>([]);

  const { isLoading, isFetching, error, refetch } = useQuery(['events'], () => axiosEvents.getEvents(), {

    onSuccess: (data) => {

      let now = new Date();
      let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let nowTimestamp = startOfDay.getTime() / 1000

      let array: EventsProps[] = []
      let lat = 0
      let lng = 0
      let hours = ''
      let start_date = ''
      let end_date = ''
      let timePeriodsArray: any[] = []
      let shortMonth: any[] = []
      const optionsForDate = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: '2-digit', year: 'numeric' } as const;

      data.map((listData: any) => {

        if (listData.time_periods == '' || !(nowTimestamp >= listData.time_periods[0].ending_date)) {

          let MonthsArray: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

          if (listData.time_periods != '') {

            let startMonth = 0
            let finishMonth = 0
            let removedItems: any[] = []

            let starting_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].starting_date * 1000))
            let ending_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].ending_date * 1000))

            if (starting_date == ending_date) {
              removedItems = [starting_date]
            }

            for (var i = 0; i < MonthsArray.length; i++) {

              if (MonthsArray[i] == starting_date)
                startMonth = MonthsArray.indexOf(MonthsArray[i]);
            
              if (MonthsArray[i] == ending_date)
                finishMonth = MonthsArray.indexOf(MonthsArray[i]);
            }


            if (startMonth < finishMonth) {
              removedItems = MonthsArray.splice(startMonth, finishMonth - startMonth + 1)
            }

            else if (startMonth > finishMonth) {
              removedItems = MonthsArray.splice(startMonth, startMonth - finishMonth)
              removedItems = [...removedItems, ending_date]
            }
  
            shortMonth.push([...removedItems])
          }

          else {
            shortMonth.push(...MonthsArray)
          }

          
          if (listData.venues.length != 0 && listData.venues[0].coordinate_x != null) {
            lat = listData.venues[0].coordinate_x
            lng = listData.venues[0].coordinate_y
          }
          else {
            lat = 0
            lng = 0
          }

          if (listData.time_periods.length == 0) {
          
            hours = '12:00 - 12:00'
            start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
            end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
            timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
          
          }
          else {
            hours = `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].starting_date * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].ending_date * 1000))}`
          
            for (let i = 0; i < listData.time_periods.length; i++) {

              start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format((listData.time_periods[i].starting_date * 1000))
              end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format((listData.time_periods[i].ending_date * 1000))
          
              timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
            }
          }



         
          array.push({
            id: `e_${listData.id.toString()}`,
            id_item: listData.id.toString(),
            place: listData.client.title,
            municipality: setMunicipality(listData.client.title),
            image: listData.image.url,
            title: listData.title,
            description: listData.description,
            lat: lat,
            lng: lng,
            category_id: listData.category.id,
            category_type: listData.category.title,
            month_code: shortMonth,
            hour: hours,
            price: listData.has_free_entry == true ? 'free' : (listData.has_free_entry == false && listData.has_external_tickets == true) ? 'out' : listData.tickets,
            time_periods: timePeriodsArray,
            venues: listData.venues
          })

          timePeriodsArray = []
          shortMonth = []
        }
      });
  
      console.log(array.sort((a : any, b : any) => {
        const endingDateComparison = a.time_periods[0].ending_date - b.time_periods[0].ending_date;
        if (endingDateComparison !== 0) return endingDateComparison;
    
        return a.title.localeCompare(b.title);
      })



      )
      setEvents(array.sort((a : any, b : any) => {
        const aEndDate : any = new Date(a.time_periods[0].ending_date.split(",")[0].split("/").reverse().join("/"));
        const bEndDate : any = new Date(b.time_periods[0].ending_date.split(",")[0].split("/").reverse().join("/"));
      
        const endDateComparison: any = aEndDate - bEndDate;
        if (endDateComparison !== 0) return endDateComparison;
      
        const aStartDate : any = new Date(a.time_periods[0].starting_date.split(",")[0].split("/").reverse().join("/"));
        const bStartDate : any = new Date(b.time_periods[0].starting_date.split(",")[0].split("/").reverse().join("/"));
      
        const startDateComparison = aStartDate - bStartDate;
        if (startDateComparison !== 0) return startDateComparison;
      
        return a.title.localeCompare(b.title, "el", { sensitivity: "base" });
      
      }))
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Events, refetch }
}



export const useEventsMapWithSeoTags = (url: string) => {
  
  const [Events, setEvents] = useState<EventsProps[]>([]);

  const { isLoading, isFetching, error, refetch } = useQuery(['eventsMunicipality'], () => axiosEvents.getEvents(), {

    onSuccess: (data) => {


      let now = new Date();
      let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let nowTimestamp = startOfDay.getTime() / 1000

      let array: EventsProps[] = []
      let lat = '0'
      let lng = '0'
      let hours = ''
      let start_date = ''
      let end_date = ''
      let timePeriodsArray: any[] = []
      let shortMonth: any[] = []
      const optionsForDate = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: '2-digit', year: 'numeric' } as const;

      data.map((listData: any) => {

        if (listData.time_periods == '' || !(nowTimestamp >= listData.time_periods[0].ending_date)) {

          let MonthsArray: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

          if (listData.time_periods != '') {

            let startMonth = 0
            let finishMonth = 0
            let removedItems: any[] = []

            let starting_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].starting_date * 1000))
            let ending_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].ending_date * 1000))

            if (starting_date == ending_date) {
              removedItems = [starting_date]
            }

            for (var i = 0; i < MonthsArray.length; i++) {

              if (MonthsArray[i] == starting_date)
                startMonth = MonthsArray.indexOf(MonthsArray[i]);
            
              if (MonthsArray[i] == ending_date)
                finishMonth = MonthsArray.indexOf(MonthsArray[i]);
            }


            if (startMonth < finishMonth) {
              removedItems = MonthsArray.splice(startMonth, finishMonth - startMonth + 1)
            }

            else if (startMonth > finishMonth) {
              removedItems = MonthsArray.splice(startMonth, startMonth - finishMonth)
              removedItems = [...removedItems, ending_date]
            }
  
            shortMonth.push([...removedItems])
          }

          else {
            shortMonth.push(...MonthsArray)
          }

          
          if (listData.venues.length != 0 && listData.venues[0].coordinate_x != null) {
            lat = listData.venues[0].coordinate_x
            lng = listData.venues[0].coordinate_y
          }
          else {
            lat = '0'
            lng = '0'
          }

          if (listData.time_periods.length == 0) {
          
            hours = '12:00 - 12:00'
            start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
            end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000)
            timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
          
          }
          else {
            hours = `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].starting_date * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].ending_date * 1000))}`
          
            for (let i = 0; i < listData.time_periods.length; i++) {

              start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format((listData.time_periods[i].starting_date * 1000))
              end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format((listData.time_periods[i].ending_date * 1000) )
          
              timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
            }
          }
         
          array.push({
            id: `e_${listData.id.toString()}`,
            id_item: listData.id.toString(),
            place: listData.client.title,
            municipality: setMunicipality(listData.client.title),
            image: listData.image.url,
            title: listData.title,
            description: listData.description,
            lat: lat,
            lng: lng,
            category_id: listData.category.id,
            category_type: listData.category.title,
            month_code: shortMonth,
            hour: hours,
            price: listData.has_free_entry == true ? 'free' : (listData.has_free_entry == false && listData.has_external_tickets == true) ? 'out' : listData.tickets,
            time_periods: timePeriodsArray,
            venues: listData.venues
          })

          timePeriodsArray = []
          shortMonth = []
        }
      });
  
      // console.log(array)
      setEvents(array)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  // const DataForSeo = useQuery(['eventsForSeo'], () => axiosEvents.getEventsForSeo(url), {

  //   onSuccess: (data) => {


  //     console.log('data', data)
  //   },

  //   onError: (error:any) => {
  //     alert(error.message)
  //   }
  // })


  return { isLoading, isFetching, error, Events, refetch }
}




function setMunicipality(checkMunicipality: string) {

  let municipality = ''

  switch (checkMunicipality) {
    case "Δήμος Πλατανιά": {
      municipality = "Πλατανιάς";
      break;
    }
    case "Δήμος Γαύδου": {
      municipality = "Γαύδος";
      break;
    }
    case "Δήμος Κισσάμου": {
      municipality = "Κίσσαμος";
      break;
    }
    case "Δήμος Σφακίων": {
      municipality = "Σφακιά";
      break;
    }
    case "Δήμος Καντάνου-Σελίνου": {
      municipality = "Κάντανος-Σέλινος";
      break;
    }
    case "Δήμος Χανίων": {
      municipality = "Χανιά";
      break;
    }
    case "Δήμος Αποκορώνου": {
      municipality = "Αποκόρωνας";
      break;
    }
    default: {
      municipality = "Κάντανος-Σέλινος";
      break;
    }
  }

  return municipality
}
