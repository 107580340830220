import React from "react"
import { Col, Row } from 'antd'
import classes from "./styles.module.css"

import GlobusSvg from '../../img/svg/globus'
import EventsSvg from '../../img/svg/events'
import TheaterSvg from '../../img/svg/theater'
import MovieSvg from '../../img/svg/movie'
import DanceSvg from '../../img/svg/dance'
import ConcertSvg from '../../img/svg/concert'
import ConcertsSvg from '../../img/svg/concerts'
import LaboratoriesSvg from '../../img/svg/laboratories'
import ExhibitionSvg from '../../img/svg/exhibition'
import ConferencesSVG from '../../img/svg/conferences'


// LEAFLET
import MarkerClusterGroup from 'react-leaflet-cluster'
import { Icon } from 'leaflet'
import L from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { EventsProps } from "../../hooks/props/event"
import point from "../../img/point.svg"
import { useTranslation } from "react-i18next"
import { polygonOptions } from '../Exploration/configCluster'


let SVG_Point = new Icon({
  iconUrl: point,
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})


interface Props{
  setPoisesMarkerExploration:any
}

const LiefletComponent: React.FC<Props> = ({ setPoisesMarkerExploration }:Props ) => {

  const { t } = useTranslation()

  const createClusterCustomIcon = (cluster:any) => {
    const count = cluster.getChildCount();
    
    let size = 'LargeXL';
    if (count < 10) {
      size = 'Small';
    }
    else if (count >= 10 && count < 20) {
      size = 'Medium';
    }
    else if (count >= 20 && count < 50) {
      size = 'Large';
    }
    const options = {
      cluster: `markerCluster${size}`,
    };
  
    return L.divIcon({
      html:`<span>${count}</span>`,
      className: `${options.cluster}`,
    });
  };



  console.log('setPoisesMarkerExploration ', setPoisesMarkerExploration)



  const MarkersPois = () => {

    return <>
      
        <MarkerClusterGroup
         iconCreateFunction={createClusterCustomIcon}
         polygonOptions={polygonOptions}  
         chunkedLoading
        >
        {setPoisesMarkerExploration.map((event: EventsProps) => {

          let textShort = event.description ? `${event.description.substring(0, 120)}` + ' ...' : ' '
          textShort = textShort.slice(0, textShort.lastIndexOf("&"))
          
          return ( 
          event.lat !== 0
          ?
            <Marker
              key={event.id}
              icon={SVG_Point}
              position={[parseFloat(event.lat), parseFloat(event.lng)]}
            >
              <Popup className="Event-popup">
                <div className={classes.MarkerGpsInfoWindow}>
                  <img src={event.image !== '' ? event.image : '../img/defaultPoisImage.jpg'} alt={event.title} />
                  <div className={classes.PointCenter}>
                    <div className={classes.MarkerPointCenterLeft}>
                      {event.title.length <= 52 ? event.title : event.title.slice(0, 52) + ' ...'}
                    </div>
                  </div>

                  <div className={classes.BoxDates}>
                    {event.time_periods && event.time_periods[0].starting_date !== '12/10/1983, 12:00' &&
                      <>
                        <div>{t('Έναρξη')}: {event.time_periods[0].starting_date}</div>
                        <div>{t('Λήξη')}: {event.time_periods[0].ending_date}</div>
                      </>
                    }
                  </div>

                  <div className={classes.MarkerPointCenterBottom}>
                    <div className={classes.MarkerPointCenterButtomLeft}>
                      <GlobusSvg width={26} height={24} fill="#fff" /><span className={classes.text}>{event.municipality === 'Κάντανος-Σέλινος' ? t('Σέλινο_Popup') : t(event.municipality)}</span>
                    </div>
                    <div className={classes.MarkerPointCenterButtomRight}>
                      {event.category_id == 18 ? <MovieSvg width={26} height={26} fill="#fff" /> :
                        event.category_id == 19 ? <LaboratoriesSvg width={26} height={26} fill="#fff" /> :
                          event.category_id == 23 ? <EventsSvg width={26} height={26} fill="#fff" /> :
                            event.category_id == 25 ? <ConcertsSvg width={26} height={26} fill="#fff" /> :
                              event.category_id == 26 ? <DanceSvg width={26} height={26} fill="#fff" /> :
                                event.category_id == 27 ? <ExhibitionSvg width={26} height={26} fill="#fff" /> :
                                  event.category_id == 28 ? <TheaterSvg width={26} height={26} fill="#fff" /> :
                                    event.category_id == 29 ? <ConcertSvg width={26} height={26} fill="#fff" /> : <ConferencesSVG width={26} height={26} fill="#fff" />}
                      <span className={classes.text}>{t(event.category_type)}</span>
                    </div>
                  </div>
                </div>
              </Popup>
              </Marker>
            : null
          )
        })}
      </MarkerClusterGroup>
    </>
  }


  return (
    <>
      <Row justify="center">
        <Col span={23}>

          <MapContainer
            key={JSON.stringify([36.09132683539974, 28.08729093999769])} // To center every time
            center={[35.249511721573, 24.757851831069722]}
            zoom={10}
            tap={false}
            style={{ height: '573px', width: '100%', zIndex: '0' }}
            zoomControl={false}
            scrollWheelZoom={true}
          >

            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
            <MarkersPois />
            <ZoomControl position='bottomright' />
            <ScaleControl position="bottomright" metric imperial={false} />

          </MapContainer>

        </Col>
      </Row>
    </>
  )
}

export default LiefletComponent