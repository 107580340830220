import axios from 'axios'
import React, { useState, useEffect } from "react"
import { Button, Modal, Form, Input, notification } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useUserLogin } from '../../../hooks/useUser'
import { useAuth } from "../../../utils/auth"
import { useNavigate } from "react-router-dom"
import { MailOutlined } from '@ant-design/icons'
import { API } from '../../../services/configuration'

interface Props{
    activeIndex: number
    clickModal: (one : number) => void,
}

const ModalLoginForm: React.FC<Props> = ({activeIndex, clickModal}) => {

    const [api, contextHolder] = notification.useNotification()
    const [apiResetPassword, contextHolderResetPassword] = notification.useNotification()

    const auth = useAuth()
    const navigate = useNavigate()

    const [email, setEmail] = useState<string>('') 
    const [password, setPassword] = useState<string>('')

    
    const { status, error, User, refetch } = useUserLogin(email, password)

    const { t } = useTranslation()

    const [form] = Form.useForm()


    useEffect(() => {
        
        if (activeIndex == 1) {
            showModal()
            clickModal(0)
        }
       
    }, [activeIndex])


    const openNotification = () => {
        api.info({
        message: t('Είσοδος στο Λογαριασμό'),
        description: t('Καλώς ορίσατε ') + User?.user?.name + ' !',
        placement:'topRight'
        });
    }


    const openNotificationResetPassword = () => {
        apiResetPassword.info({
        message: t('Υπενθύμιση κωδικού πρόσβασης'),
        description: t('Καλώς ορίσατε '),
        placement:'top'
        });
    }

    const showModal = () => {
        Modal.info({
            centered: true,
            closable: true,
            footer: null,
            title: t('Είσοδος στο Λογαριασμό'),
            width: 460,
            content: (
                <>
                    <br />
                    <Form
                        layout="vertical"
                        wrapperCol={{ span: 24 }}
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                        style={{ maxWidth: 360 }}
                    >
                        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item style={{textAlign: 'center'}}>
                            <Button className={classes.signUpBtn} htmlType="submit">{t('Σύνδεση')}</Button>
                            <span className={classes.textBoldSignIn} onClick={showResetModal}>{t('Υπενθύμιση κωδικού πρόσβασης')}</span>

                            {/* <Button className={classes.signUpBtn} htmlType="button" onClick={onReset}>{t('Επαναφορά')}</Button> */}
                            {/* <Button type="link" htmlType="button" onClick={onFill} >Fill form</Button>  */}
                            
                        </Form.Item>
                    </Form>
                </>
            ),
            onOk() { },
        });
    };

    useEffect(() => {
        
        if (User?.user.name) {
            openNotification()
            setTimeout(() => {
             navigate('/favorites')
          }, 1500)
           
        }
            
    }, [User?.user.name])


    useEffect(() => {


        if (email !== '' && password !== '') {
            refetch()
        }
            
        auth.login(User?.user.id, User?.user.name, User?.user.username); 
      }, [email, password, User?.user.name])

    
    const onFinish = (values: any) => {
       
        setEmail(values.email)
        setPassword(values.password)
        // setTimeout(() => {
        //     openNotification()
        //   }, 2000)
        Modal.destroyAll()
    }


    // const onFinishResetPassword = async (values: any) => {
    //     axios.post(
    //         `${API}/el/passwordForgot`,
    //         {
    //             email: values.emailReset
    //         },
    //         {
    //             headers: {
    //                 'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq', // Headers
    //             },
    //         }
    //     ).catch(function (error) {
    //         if (error.response) {
    //             // The request was made and the server responded with a status code
    //             // that falls out of the range of 2xx
    //             console.log(error.response.data);
    //             console.log(error.response.status);
    //             console.log(error.response.headers);
    //         } else if (error.request) {
    //             // The request was made but no response was received
    //             // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //             // http.ClientRequest in node.js
    //             console.log(error.request);
    //         } else {
    //             // Something happened in setting up the request that triggered an Error
    //             console.log('Error', error.message);
    //         }
    //         console.log(error.config);
    //     });
    // }



    // const onFinishResetPassword = async (values: any) => {
      
    //     console.log('myEmail', myEmail)

    //     if (values?.emailReset !== undefined) {

    //         console.log('values.emailReset : ', myEmail)

    //         const response = await axios.post(
    //             `${API}/el/passwordForgot`,
    //             {
    //                 email: values?.emailReset
    //             },
    //             {
    //                 headers: {
    //                     'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq', // Headers
    //                 },
    //             }
    //         ).catch(function (error) {
    //             console.log(error.response.data.error.email)
    //             if (error.response.status == 422) {
    //                 Modal.error({
    //                     centered: true,
    //                     title: '',
    //                     // content: t('Δεν βρέθηκε λογαριασμός με το συγκεκριμένο Email.'),
    //                     content: error.response.data.error.email,
    //                 });
    //             }
    //         });
        
    //         console.log(response)
    //         // console.log(error?.response)

    //         if (response?.status === 200) {

            
    //             // openNotificationResetPassword();
    //             if (error?.response?.data?.error.email == 'Δεν υπάρχει εγγεγραμμένος χρήστης με αυτή τη διεύθυνση e-mail.') {
    //                 Modal.error({
    //                     centered: true,
    //                     title: '',
    //                     content: t('Δεν βρέθηκε λογαριασμός με το συγκεκριμένο Email.'),
    //                 });
    //             }
                
    //             else if (error?.response?.data?.error.email == 'Please wait before retrying.') {
    //                 Modal.error({
    //                     centered: true,
    //                     title: '',
    //                     content: t('Please wait before retrying.'),
    //                 });
    //             }

                
    //             else if (response?.data.error == 'Απαιτείται τιμή για το πεδίο email.') {
    //                 Modal.error({
    //                     centered: true,
    //                     title: '',
    //                     content: t('Δεν βρέθηκε λογαριασμός με το συγκεκριμένο Email.'),
    //                 });
    //             }
                
    //             // else if (response?.data.message == 'Ελέγξτε τον email λογαριασμό που δηλώσατε, για να ακολουθήσετε το σύνδεσμο που θα σας επιτρέψει να κάνετε reset το κωδικό σας! Παρακαλώ ελέγξτε τον φάκελο spam, σε περίπτωση που δεν εντοπίζετε το email στο inbox σας!') {
    //             //     Modal.success({
    //             //         centered: true,
    //             //         title: '',
    //             //         content: t('Σας εστάλη μήνυμα στο email σας για την επαναφορά του κωδικού πρόσβασης σας'),
    //             //     });
    //             // }     
    //             // else { 
    //             //     Modal.success({
    //             //         centered: true,
    //             //         title: '',
    //             //         content: t('Σας εστάλη μήνυμα στο email σας για την επαναφορά του κωδικού πρόσβασης σας'),
    //             //     });
    //             // }
    //         }

    //         // if (response.status === 200) {
    //         //     // openNotificationResetPassword();
    //         //     Modal.success({
    //         //         centered: true,
    //         //         title: '',
    //         //         content: t('Σας εστάλη μήνυμα στο email σας για την επαναφορά του κωδικού πρόσβασης σας'),
    //         //     });
    //         // }
    //     }    
       
    // }


    // const onFinishResetPassword = async (values: any) => {
    //     console.log('values', values.emailReset); // Access email from form values
    //     try {
    //         const response = await axios.post(
    //             `${API}/el/passwordForgot`,
    //             { email: values.emailReset },
    //             {
    //                 headers: {
    //                     'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq', // Headers
    //                 },
    //             }
    //         );
    
    //         setTimeout(() => {
    //             if (response.status === 200) {
    //                 Modal.success({
    //                     centered: true,
    //                     title: '',
    //                     content: t('Σας εστάλη μήνυμα στο email σας για την επαναφορά του κωδικού πρόσβασης σας'),
    //                 });
    //             } else {
    //                 // Modal.error({ ... });
    //             }
    //         }, 2000);
    //     } catch (error) {
    //         // Handle errors gracefully
    //         // Modal.error({ ... });
    //     }
    // };
    
    
    const onFinishResetPassword = async (values: any) => {
        
        console.log('values', values.emailReset); // Access email from form values
       
        try {
            const response = await fetch(`${API}/el/passwordForgot`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the content type
                    'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq', // Headers
                },
                body: JSON.stringify({
                    email: values.emailReset,
                }),
            });
    
            const data = await response.json(); // Parse the response body as JSON

            console.log('response : ', response)


            if (response.status == 200) {
                Modal.success({
                    centered: true,
                    title: '',
                    content: t('Σας εστάλη μήνυμα στο email σας για την επαναφορά του κωδικού πρόσβασης σας'),
                    onOk() {
                        window.location.reload();
                    }
                });
            }

            if (response.status == 422) {
                Modal.error({
                    centered: true,
                    title: '',
                    content: t('Δεν βρέθηκε λογαριασμός με το συγκεκριμένο Email.'),
                    onOk() {
                        window.location.reload();
                    }
                });
            }


            // If the response is successful
            // if (data.status === 'success') {
            //     Modal.success({
            //         centered: true,
            //         title: '',
            //         content: t('Σας εστάλη μήνυμα στο email σας για την επαναφορά του κωδικού πρόσβασης σας'),
            //     });
            // } else {
            //     // Handle failure case if needed
            //     // Modal.error({
            //     //     title: t('Error'),
            //     //     content: t('An error occurred while resetting the password.'),
            //     // });
            // }
        } catch (error) {
            console.error(error);

            Modal.error({
                title: t('Error'),
                content: t('An error occurred while resetting the password.'),
            });
        }
    };


    const onReset = () => { form.resetFields(); }

    const onFill = () => { form.setFieldsValue({ email: 'sergios@dotsoft.gr', password: '12345678' }); }



    const showResetModal = () => {
        Modal.info({
            centered: true,
            closable: true,
            footer: null,
            title: t('Υπενθύμιση κωδικού πρόσβασης'),
            width: 460,
            content: (
                <>
                    <div style={{fontSize:'16px', fontWeight: 400, lineHeight: 1.2 , padding: '12px 0px 16px 0px'}}>
                        {t('modal_reset_user_password')}
                    </div>
                    <Form
                        layout="vertical"
                        autoComplete="off"
                        wrapperCol={{ span: 24 }}
                        form={form}
                        onFinish={onFinishResetPassword}
                        // onChange={(e)=>updateEmail(e)} 
                        style={{ maxWidth: 360 }}
                    >

                        <Form.Item label="Email" name="emailReset" hasFeedback rules={[{ type:'email', required: true, message: `${t('Enter your Email')}` }]}>
                            <Input prefix={<MailOutlined  className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε το Email σου εδώ') || undefined } />
                        </Form.Item>

                        <Form.Item>
                            <Button className={classes.signUpBtn} htmlType="submit">{ t('Επαναφορά')}</Button>
                        </Form.Item>

                    </Form>
                </>
            ),
            onOk() { },
        });
    };



    return (
        <>
            {/*
             {contextHolder}
             {contextHolderResetPassword} 
            */}
            <div key="notification-holder-general">
                {contextHolder}
            </div>
            <div key="notification-holder-reset-password">
                {contextHolderResetPassword}
            </div>
            <Button size='large' className={classes.LoginBtn} onClick={showModal}> {t('Είσοδος στο Λογαριασμό')} </Button>
        </>
    )
}
export default ModalLoginForm
