import axios from 'axios'
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Row, Col, Button, Divider, notification, Modal } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, UserDeleteOutlined } from '@ant-design/icons'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import { useAuth } from "../../utils/auth"
import {API, Header} from '../../services/configuration'
const MyAccount: React.FC = () => {
    const [api, contextHolder] = notification.useNotification()
    const { t } = useTranslation()
    const auth = useAuth()
    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);



    const navigateHome = () => {
        navigate('/');
    };


    const openNotification = () => {
        auth.logout()
        api.info({
        message: t('Αποσύνδεση'),
        description: t('Αποσυνδεθήκατε επιτυχώς!'),
        placement:'topRight'
        });
    }


    const redirectToHome = () => {
        window.location.href = '/'; // Перенаправляем на главную страницу
    };

    function confirm() {
        Modal.confirm({
            title: <h4 style={{fontSize:'19px', fontWeight: 500}}>{t('Διαγραφή λογαριασμου')}</h4>,
            centered: true,
            cancelText: t("'Ακυρο"),
            okText: t('Διαγραφή λογαριασμου'),
            icon: <UserDeleteOutlined />,
            cancelButtonProps: {
                danger: false,
                style:{ backgroundColor: '#275674', color:'white', fontSize:'17px', height: '40px'}
            },
            okButtonProps: {
                danger: true,
                style: { backgroundColor: isHovered ? '#ff4d4f' : '#cf1e20', fontSize: '17px', height: '40px' },
                onMouseEnter: () => setIsHovered(true),
                onMouseLeave: () => setIsHovered(false),
            },
            content: (
                <div style={{fontSize:'16px', fontWeight: 400, lineHeight: 1.2 , padding: '12px 0px 16px 0px'}}>
                    {t('modal_delete_user_title')}
                </div>
            ),
            async onOk() {
                // return new Promise((resolve, reject) => {
                //     setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Modal errors!'));

                return await axios.delete(`${API}/user`, {
                    headers: {
                        'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq',
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    }
                })
                .then(() => {
                    Modal.success({
                        centered: true,
                        title: t('modal_success_delete_user'),
                        onOk: () => { redirectToHome()}
                    });
                
                    // setTimeout(() => {
                    //     redirectToHome();
                    // }, 3000);
                })
                .catch(() => Modal.error({ title: t('modal_onsuccess_delete_user') }));
            }
        });
    }


 
    return (
        <>
            {contextHolder}
            <HelmetComponent title="Ο Λογαριασμός μου" descrition="My Account page" />
            <div className={classes.container} style={{ marginBottom: 43 }}>
                <Row>
                    <Col xs={24} sm={24} md={7} lg={7}>
                        <div className={classes.title}>
                            {/* <Row justify="center">
                                <Button size='large' className={classes.BackBtn} onClick={() => navigate(-1)}>{t('Επιστροφή')}</Button>
                            </Row> */}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10}>
                        <div className={classes.title}>
                            {t('Ο Λογαριασμός μου')}
                        </div>
                    </Col>
                </Row>
            </div>


            <div style={{ marginBottom: 26 }}>
                <Row justify="center">
                    <div className={classes.AccountSection}>
                        <div className={classes.pointTitle}>{t('Ρυθμίσεις Λογαριασμού')}</div>
                    </div>
                </Row>
                <Row justify="center">
                    <div id="TouristMaps" className={classes.UsefulSection}>
                        {/* <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Τουριστικοί Χάρτες')}</div>
                        <div className={classes.subTitleSection}> {t('Δείτε τη συλλογή χαρτών που θα σας φανούν χρήσιμοι σε διάφορες περιπτώσεις στην επίσκεψή σας στο Νομό Χανίων')}:</div>
                        */}
                         <Row>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className={classes.sectionTitle}>
                                    {t('Όνομα του χρήστη')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className={classes.Email}>
                                { auth.userData.name }
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className={classes.sectionTitle}>
                                    {t('Email Λογαριασμού')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className={classes.Email}>
                                { auth.userData.email }
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Όροι και Προυποθέσεις χρήσης')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.chania.gr/enimerosi/termsofuse/termsofuse.html'
                                        target='_blank'
                                        size='large'
                                        className={classes.GoToBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Αποσύνδεση')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        size='large'
                                        className={classes.GoToBtn}
                                        onClick={()=> openNotification() }>
                                        {t('Αποσύνδεση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <div className={classes.sectionTitle}>
                                    {t('Διαγραφή')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <div className={classes.deleteAccount}>
                                    <Button
                                        type="link"
                                        danger
                                        onClick={confirm}>
                                        <u>{t('Διαγραφή Λογαριασμού')}</u>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>
            <div style={{ marginBottom: 58 }}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={11} lg={11}>
                        <div className={classes.sectionTitle}>
                            <Button
                                size='large'
                                icon={<ArrowLeftOutlined />}
                                onClick={() => {
                                    navigate(-1)
                                }}
                                className={classes.ReturnBtn}>&nbsp;&nbsp;{t('Επιστροφή')}
                            </Button>

                        </div>
                    </Col>
                    <div className={classes.space}></div>
                    <Col xs={24} sm={24} md={11} lg={11}>
                        <div className={classes.sectionTitle}>
                            <Button
                                size='large'
                                className={classes.HomeBtn}
                                onClick={navigateHome}>
                                {t('Αρχική Σελίδα')}&nbsp;&nbsp;<ArrowRightOutlined />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
           
        </>
    )
}

export default MyAccount
